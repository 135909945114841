import React from "react";
import Layout from "../components/Layout";
import ComingSoon from "../components/ComingSoon";

const member = () => {
  return (
    <Layout>
      <ComingSoon />
    </Layout>
  );
};

export default member;
